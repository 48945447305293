import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', // 这个就是根路径，也就是直接访问localhost:8080
    redirect: '/indexPage', // 这个页面的名字
  },
  {
    path: '/indexPage',
    name: 'indexPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/indexPage.vue')
  },
  {
    path: '/businessScope',
    name: 'businessScope',
    component: () => import(/* webpackChunkName: "about" */ '../views/businessScope.vue'),
  },
  {
    path: '/publishingSystem',
    name: 'publishingSystem',
    component: () => import('../views/businessScope/publishingSystem.vue'),
  },
  {
    path: '/device',
    name: 'device',
    component: () => import('../views/businessScope/warnDevice.vue'),
  },
  {
    path: '/softHardware',
    name: 'softHardware',
    component: () => import('../views/businessScope/softHardware.vue'),
  },
  {
    path: '/informatization',
    name: 'informatization',
    component: () => import('../views/businessScope/informationIntegration.vue'),
  },
  {
    path: '/softwareDetail',
    name: 'softwareDetail',
    component: () => import('../views/businessScope/softwareDetail.vue'),
  },
  {
    path: '/hardwareDetail',
    name: 'hardwareDetail',
    component: () => import('../views/businessScope/hardwareDetail.vue'),
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/aboutUs.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/industryNews.vue')
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/newsDetail.vue')
  },
  {
    path: '/caseList',
    name: 'caseList',
    component: () => import(/* webpackChunkName: "about" */ '../views/caseList.vue')
  },
  {
    path: '/caseDetail',
    name: 'caseDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/caseDetail.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/contactUs.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
