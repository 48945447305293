import axios from 'axios';
import { Message } from 'element-ui';

// 创建一个 axios 实例
const instance = axios.create({
    // baseURL: 'http://192.168.88.122', // 基础 URL正式
    // baseURL: 'http://zhaopin.fzbykj.com', // 基础 URL固定
    baseURL: 'https://www.fzbykj.com/', // 基础 URL固定

    timeout: 500000, // 请求超时时间
});

// 请求拦截器
instance.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么

        // 可以在请求头中添加 token
        // config.headers['Authorization'] = 'Bearer ' + getToken();

        return config;
    },
    function (error) {
        // 处理请求错误
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    function (response) {
        // 对响应数据做些什么

        return response.data;
    },
    function (error) {
        // 处理响应错误
        Message.error('请求出错，请重试！');
        return Promise.reject(error);
    }
);

// 封装 GET 请求
export function get(url, params) {
    return instance.get(url, { params });
}

// 封装 POST 请求
export function post(url, data) {
    return instance.post(url, data);
}
export default instance;
