<template>
  <div id="app" v-disable-ctrl-scroll>
    <navigation-bar></navigation-bar>
    <router-view/>
    <foot-bar></foot-bar>
  </div>
</template>
<script >
import navigationBar from "@/components/navigationBar.vue";
import footBar from "@/components/footBar.vue";

export default {
  components:{
    footBar,
    navigationBar
  },
}
</script>
<style lang="scss">
* {
   margin: 0;
   padding: 0;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   -ms-overflow-style: none;
   scrollbar-width: none;
  font-family: douyuFont;
  touch-action: pan-y;
}


html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

#app {
  -webkit-overflow-scrolling: touch;
  overflow: scroll;
  position: absolute;
  left:0;
  top:0;
 }



  ::-webkit-scrollbar{
  display: none;
}
body{
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
