import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import disableCtrlScroll from '../src/plugin/disableCtrlScroll'


Vue.directive('disable-ctrl-scroll', disableCtrlScroll);//禁止Ctrl+鼠标滚轮缩放页面大小
Vue.use(ElementUI);
Vue.config.productionTip = false
import VueAMap from 'vue-amap'
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '724780805561df8e0036837d7d85e183',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.4'
});
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'



Vue.use(VueQuillEditor)
import axios from "axios";
Vue.prototype.$axios = axios; //将axios绑定到vue的原型上

import '../src/assets/font/font.css'
Vue.prototype.$imgUrl = "https://www.fzbykj.com/";


Vue.prototype.$bus = new Vue();
Vue.prototype.$eventFocus = new Vue();

Vue.prototype.$eventBus = new Vue();
Vue.prototype.$eventTop = new Vue();

Vue.prototype.$getImgUrl = function (url) {
  return Vue.prototype.$imgUrl + url
}


// 跳转后返回顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
  // console.log(to,from,next)
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
