<template>
    <div class="footBar">
        <div class="submit-allBox">
            <div class="submit-leftBox">
                <div class="contact-timeBox">
                    <div class="time-numbers">
                        <div class="time-number">0</div>
                        <div class="time-number">0</div>
                        <div class="bumbernone">:</div>
                        <div class="time-number">6</div>
                        <div class="time-number">0</div>
                    </div>
                    <div class="time-txts">
                        <div class="time-txt1">只需60分钟</div>
                        <div class="time-txt2">回复您的信息</div>
                    </div>
                </div>
                <div class="contact-textBox">
                    <div class="contact-title">联系我们</div>
                    <div class="contact-details">
                        <div class="contact-detail">
                            电话：{{ information.phone }}
                        </div>
                        <div class="contact-detail">
                            地址：{{ information.address }}
                        </div>
                    </div>
                    <div class="contact-button">联系我们</div>
                </div>
            </div>
            <div class="submit-form">
                <div class="form-titles">
                    <div>提交您的信息</div>
                    <div class="English-title">SUBMIT YOUR REQUIREMENTS</div>
                </div>
                <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                >
                    <el-form-item label="" prop="name">
                        <el-input
                            ref="inputName"
                            v-model="ruleForm.name"
                            placeholder="您的名字"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="phone">
                        <el-input
                            ref="inputPhone"
                            v-model="ruleForm.phone"
                            placeholder="您的电话"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="remarks">
                        <el-input
                            ref="textareaRemarks"
                            class="remarks"
                            type="textarea"
                            v-model="ruleForm.remarks"
                            placeholder="填写您的需求"
                        ></el-input>
                    </el-form-item>
                    <el-form-item class="sub-button">
                        <el-button
                            class="submit-button"
                            type="primary"
                            @click="submitForm('ruleForm')"
                            >提交</el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="information-allBox">
            <div class="foot-navgationBox">
                <div
                    class="foot-navgation"
                    v-for="(item, index) in navList"
                    :key="index"
                >
                    <div
                        class="parentTxt"
                        @click="
                            changeNav(
                                item,
                                index,
                                item.children ? item.children[0] : null,
                                0
                            )
                        "
                    >
                        {{ item.name }}
                    </div>
                    <div class="childrens">
                        <div
                            class="child"
                            v-for="(cItem, cIndex) in item.children"
                            :key="cIndex"
                            @click="changeNav(item, index, cItem, cIndex)"
                        >
                            {{ cItem.name }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="codeBox">
                <img :src="$getImgUrl(information.qrcode)" />
                <span>扫码</span>
                <span>联系我们</span>
            </div>
            <div class="information-detailBox">
                <div class="mobile-box">
                    <img src="../assets/images/mobile2.png" />
                    <span>{{ information.phone }}</span>
                </div>
                <div class="address-box">
                    <!--          <img :src="$getImgUrl(information.company_logo)">-->
                    <img src="../assets/images/logo2.png" />
                    <span>{{ information.address }}</span>
                </div>
                <div class="filings">{{ information.filings }}</div>
            </div>
        </div>
        <div class="toTop" @click="toTop">返回顶部</div>
    </div>
</template>
<script>
import { web_menu, bottom_data, add_leave_word } from "../utils/api";
export default {
    data() {
        return {
            information: {
                "phone": "15985761802",//电话
                "address": "福州市鼓楼区五四路宁德大厦906",//地址
                "qrcode": "/uploads/20240227/44bc43a8186a380af5b996905968cf25.png",//二维码
                "corporate_name": "福州奔阳信息科技有限公司",//公司名称
                "filings": "Codjjdjds2021 Hjijsaijsassijiajsijsjijikikinn闽ICP备2020121号-1",//备案号
                "company_logo": "/uploads/20240228/dfc07d78f072118a15efbad8fd8e6f66.png"//公司logo
            },
            ruleForm: {
                name: '',
                phone: '',
                remarks: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入您的名字', trigger: 'blur' },
                    { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入您的电话', trigger: 'blur' },
                    { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
                ],
                remarks: [
                    { required: true, message: '请填写您的需求', trigger: 'blur' }
                ],
            },
            navList: [
                {
                    name: '首页',
                    type: 0,//0-站内锚点跳转 1-跳转新页面
                    url: '/indexPage',
                    children: [
                        {
                            label: '业务范畴',
                            id: 1
                        },
                        {
                            label: '企业简介',
                            id: 2
                        },
                        {
                            label: '案例中心',
                            id: 3
                        },
                        {
                            label: '荣誉资质',
                            id: 4
                        },
                        {
                            label: '合作伙伴',
                            id: 5
                        }
                    ]
                },
                {
                    name: '业务范畴',
                    type: 1,//0-站内锚点跳转 1-跳转新页面
                    url: '/businessScope',
                    children: [
                        {
                            label: '地震预警发布系统',
                            url: '/publishingSystem'
                        },
                        {
                            label: '地震预警设备',
                            url: '/device'
                        },
                        {
                            label: '软硬件研发',
                            url: '/softHardware'
                        },
                        {
                            label: '信息化集成',
                            url: '/informatization'
                        },
                    ]
                },
                {
                    name: '关于我们',
                    type: 0,//0-站内锚点跳转 1-跳转新页面
                    url: '/aboutUs',
                    children: [
                        {
                            label: '企业简介',
                            id: 1
                        },
                        {
                            label: '荣誉资质',
                            id: 2
                        },
                        {
                            label: '企业新闻',
                            id: 3
                        },
                        {
                            label: '合作伙伴',
                            id: 4
                        },
                    ]
                },
                {
                    name: '案例中心',
                    type: 0,//0-站内锚点跳转 1-跳转新页面
                    url: '/caseList',
                    children: [
                        {
                            label: '全部案例',
                            id: 0//0-全部，1-地震预警系统，2-软件开发和接口请求一样
                        },
                        {
                            label: '地震预警系统',
                            id: 1
                        },
                        {
                            label: '软件开发',
                            id: 2
                        },
                    ]
                },
                {
                    name: '联系我们',
                    type: 0,//0-站内锚点跳转 1-跳转新页面
                    url: '/contactUs',
                    children: [
                        {
                            label: '联系我们',
                            id: 1
                        },
                    ]
                }
            ],
            selectIndex: 0,
            selectChild: 0,
        }
    },
    mounted() {
        this.getMenu()
        this.getInformation()
    },
    created() {
        this.$eventFocus.$on('loseFocus', this.toLoseFocus);
    },
    methods: {
        toLoseFocus(event) {
            if (!this.$refs.inputName.$el.contains(event.target) &&
                !this.$refs.inputPhone.$el.contains(event.target) &&
                !this.$refs.textareaRemarks.$el.contains(event.target)) {
                // 如果点击的不是输入框或文本框，则执行失去焦点的操作
                this.$refs.inputName.blur(); // 失去名字输入框焦点
                this.$refs.inputPhone.blur(); // 失去电话输入框焦点
                this.$refs.textareaRemarks.blur(); // 失去备注文本框焦点
            }
        },
        changeNav(item, index, cItem, cIndex) {
            if (item.type == 0) {
                console.log(this.$route.path, 11)
                if (this.$route.path == item.url) {
                    let _obj = {
                        parent: item.url,
                        selectChild: cIndex + 1,
                        id: cItem.id
                    }
                    this.$eventBus.$emit('changeChild', _obj);
                    this.$emit('change', JSON.stringify(cItem))
                    this.selectIndex = index
                    this.selectChild = cIndex
                    this.selected()
                    window.sessionStorage.setItem('selectIndex', index)
                    window.sessionStorage.setItem('selectChild', cIndex)
                } else {
                    this.$router.push(item.url)
                    let _obj = {
                        parent: item.url,
                        selectChild: cIndex + 1,
                        id: cItem.id
                    }
                    this.$eventBus.$emit('changeChild', _obj);
                    this.$emit('change', JSON.stringify(cItem))
                    this.selectIndex = index
                    this.selectChild = cIndex
                    this.selected()
                    window.sessionStorage.setItem('selectId', cItem.id)
                    window.sessionStorage.setItem('selectIndex', index)
                    window.sessionStorage.setItem('selectChild', cIndex)
                }
            } else if (item.type == 1) {
                if (this.$route.path == cItem.url) {
                    return
                } else {
                    this.$router.push(cItem.url)
                    this.selectIndex = index
                    this.selectChild = cIndex
                    this.selected()
                    window.sessionStorage.setItem('selectIndex', index)
                    window.sessionStorage.setItem('selectChild', cIndex)
                }
            }
        },
        submitForm(formName) {
            // console.log(this.ruleForm)
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let that = this
                    add_leave_word(that.ruleForm).then((res) => {
                        if (res.code == 1) {
                            that.$message.success('提交成功')
                            that.ruleForm = {
                                name: '',
                                phone: '',
                                remarks: '',
                            }
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    this.$message.error('请填写正确信息');
                    return false;
                }
            });
        },
        getMenu() {
            let that = this
            web_menu().then((res) => {
                if (res.code == 1) {
                    that.navList = res.data
                }
            });
        },
        getInformation() {
            let that = this
            bottom_data().then((res) => {
                if (res.code == 1) {
                    that.information = res.data
                }
            });
        },
        toTop() {
            // window.scrollTo(0,0);
            // console.log('scrollTop')
            this.$eventTop.$emit('scrollTop', 1);
        },
        selected() {
            let _obj = {
                selectIndex: this.selectIndex,
                selectChild: this.selectChild
            }
            this.$bus.$emit('selected', JSON.stringify(_obj));
        },
    }
}
</script>
<style scoped lang="scss">
.footBar {
    background-image: url("https://www.fzbykj.com//uploads/20240327/07058e401e198972e7500a2c55836d24.png");
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #464646;
    width: 100vw;
    //height: 100vh;
}
.submit-allBox {
    padding: 25vh 10vh 0 4vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .submit-leftBox {
        width: 20%;
        margin: 0 10% 0 5%;
        text-align: left;
        .contact-timeBox {
            display: flex;
            align-items: center;
            padding-bottom: 0.5vw;
            border-bottom: 1px solid #47433f;
            .time-numbers {
                display: flex;
                align-items: center;
                .time-number {
                    background: #2a2b2c;
                    color: #f58d17;
                    padding: 0.2vw;
                    font-size: 2vw;
                    border-radius: 0.3vw;
                    margin-right: 0.2vw;
                }
                .bumbernone {
                    color: #f58d17;
                    padding: 0.1vw;
                    font-size: 2vw;
                    font-weight: 600;
                    border-right: 0.1vw;
                    margin-right: 0.2vw;
                }
            }
            .time-txts {
                margin-left: 0.3vw;
                color: #e98715;
                font-size: 0.9vw;
                .time-txt2 {
                    font-size: 0.6vw;
                }
            }
        }
        .contact-textBox {
            .contact-title {
                margin: 0.5vw 0;
                color: #dededd;
                font-size: 0.9vw;
            }
            .contact-details {
                display: flex;
                flex-direction: column;
                .contact-detail {
                    font-size: 0.7vw;
                    color: #c9c8c7;
                    margin-bottom: 0.1vw;
                }
            }
            .contact-button {
                margin-top: 0.5vw;
                background: #ff9317;
                color: #f5f5f5;
                padding: 0.2vw 1vw;
                border-radius: 0.3vw;
                width: fit-content;
                font-size: 0.8vw;
            }
        }
    }
    .submit-form {
        background: #1f1a15;
        display: flex;
        flex-direction: column;
        padding: 0 4vw;
        //margin-right: 2vw;
        .form-titles {
            display: flex;
            align-items: baseline;
            padding: 1vw 0;
            color: #fff;
            font-size: 1vw;
            .English-title {
                font-size: 0.8vw;
                margin-left: 1vw;
                color: #484542;
            }
        }
        .el-form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        ::v-deep .el-input__inner {
            background-color: #4b4b4b;
            border: 1px solid #4b4b4b;
            width: 17vw;
            color: #fff;
        }
        ::v-deep .el-input__inner:hover {
            border-color: #4b4b4b;
        }
        ::v-deep .el-textarea__inner {
            background-color: #4b4b4b;
            border: 1px solid #4b4b4b;
            width: 35vw;
            height: 13vh;
            color: #fff;
            resize: none; //主要是这个样式
        }
        ::v-deep .el-textarea__inner:hover {
            border-color: #4b4b4b;
        }
        ::v-deep .el-form-item {
            margin-bottom: 1.2vw;
        }

        ::v-deep .el-form-item__error {
            font-size: 14px;
            padding-top: 7px;
        }
        .submit-button {
            background: #ff9317;
            border-color: #ff9317;
            padding: 0.3vw 2vw;
        }
    }
}

.information-allBox {
    padding: 1vw 10vw;
    display: flex;
    justify-content: space-around;
    background: #ff9317;
    .foot-navgationBox {
        display: flex;
        .foot-navgation {
            display: flex;
            flex-direction: column;
            margin-right: 1.5vw;
            text-align: left;
            color: #110a02;
            .parentTxt {
                cursor: pointer;
                font-size: 1vw;
                margin-bottom: 0.3vw;
            }
            .childrens {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                font-size: 0.8vw;
                .child {
                    margin-bottom: 0.2vw;
                }
            }
        }
    }
    .codeBox {
        display: flex;
        flex-direction: column;
        color: #110a02;
        font-size: 0.8vw;
        align-items: center;
        img {
            width: 5vw;
            margin-bottom: 0.2vw;
        }
    }
    .information-detailBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        .mobile-box {
            display: flex;
            align-items: end;
            background: #fff;
            width: fit-content;
            padding: 0.3vw 1vw;
            font-size: 1.5vw;
            font-weight: 600;
            margin-bottom: 1.6vw;
            img {
                width: 1.5vw;
                margin-right: 0.8vw;
            }
            span {
                height: 1.5vw;
                line-height: 1.5vw;
            }
        }
        .address-box {
            display: flex;
            align-items: end;
            width: fit-content;
            font-size: 0.9vw;
            margin-bottom: 1vw;
            color: #110a02;
            img {
                width: 1vw;
                margin-right: 0.5vw;
            }
        }
        .filings {
            font-size: 0.9vw;
            color: #110a02;
        }
    }
}
.toTop {
    color: #ff9317;
    font-size: 1.2vw;
    margin-top: 1.2vw;
    cursor: pointer;
}

@media (max-width: 1920px) {
    .footBar {
        .toTop {
            font-size: 20px !important;
        }
    }
}
@media (max-width: 1900px) {
    .footBar {
        //height: 100%;
        //padding-bottom: 5vh !important;
        .submit-allBox {
            padding: 40px 0 0 0;
        }
    }
}
@media (max-width: 1800px) {
    .submit-allBox {
        .submit-leftBox {
            width: 30%;
            .contact-timeBox {
                .time-numbers {
                    .time-number,
                    .bumbernone {
                        font-size: 35px;
                    }
                }
                .time-txts {
                    .time-txt1 {
                        font-size: 20px;
                    }
                    .time-txt2 {
                        font-size: 15px;
                    }
                }
            }
            .contact-textBox {
                .contact-title {
                    font-size: 20px;
                }
                .contact-details {
                    .contact-detail {
                        font-size: 18px;
                    }
                }
                .contact-button {
                    font-size: 20px;
                }
            }
        }
        .submit-form {
            .form-titles {
                font-size: 20px !important;
                .English-title {
                    font-size: 18px !important;
                }
            }
            .el-form-item {
                margin-bottom: 22px;
            }
        }
    }
}

/* 小屏幕（手机）响应式样式 */
@media (max-width: 768px) {
    .footBar {
        flex-direction: column;
        align-items: center;
        height: 100%;
        .submit-allBox {
            padding: 10vw 0vh 0 0vh !important;
            .submit-leftBox {
                width: 100%;
                margin-right: 0;
                .contact-timeBox {
                    margin-bottom: 1vw !important;

                    .time-numbers {
                        .time-number {
                            font-size: 5vw !important;
                        }
                        .bumbernone {
                            font-size: 5vw !important;
                        }
                    }

                    .time-txts {
                        display: flex;
                        margin-left: 3vw !important;

                        .time-txt1 {
                            font-size: 3vw !important;
                            width: 125px;
                            margin-top: 0.5vw;
                        }
                        .time-txt2 {
                            font-size: 3vw !important;
                            margin-top: 0.5vw;
                        }
                    }
                }
                .contact-textBox {
                    .contact-title {
                        margin-bottom: 1vw !important;
                        text-align: left;
                        width: 100%;
                        font-size: 4vw !important;
                    }
                    .contact-details {
                        .contact-detail {
                            font-size: 4vw !important;
                            width: 100vw;
                            margin-bottom: 1vw !important;
                        }
                    }
                    .contact-button {
                        display: none;
                        border-radius: 5px !important;
                        padding: 5px 10px !important;
                        font-size: 4vw !important;
                    }
                }
            }

            .submit-form {
                text-align: center;
                width: 100%;
                max-width: 100%; /* 根据需要调整最大宽度 */
                .form-titles {
                    font-size: 3vw !important;
                    .English-title {
                        margin-left: 3vw !important;
                        font-size: 3.5vw !important;
                    }
                }
                .sub-button {
                    margin-left: 65%;
                }

                .submit-button {
                    margin-left: 80%;
                    margin-top: 12px;
                }
            }
        }

        .information-allBox {
            padding: 1vw 4vw !important;
            .information-detailBox {
                width: 70% !important;
                .mobile-box {
                    img {
                        width: 3.5vw;
                    }
                }
            }
        }

        .toTop {
            padding-bottom: 5vw !important;
            font-size: 5vw !important;
        }
    }

    .remarks {
        width: 300px;
    }

    .submit-button {
        padding: 5px 10px !important;
        font-size: 15px;
    }

    ::v-deep .submit-allBox .submit-form .el-input__inner {
        width: 60vw;
        color: #fff;
    }

    ::v-deep .submit-allBox .submit-form .el-textarea__inner {
        width: 90vw;
        color: #fff;
    }
}

@media (max-width: 360px) {
    .softHardwareInformation-box {
        height: 70vh !important;
    }
    ::v-deep .submit-allBox {
        .submit-form {
            .el-textarea__inner {
                width: 340px;
            }
        }
    }
}

@media (orientation: portrait) {
    .information-allBox {
        .foot-navgationBox {
            display: none;
        }

        .codeBox {
            width: 25%;
            align-items: center;
            font-size: 12px;
            img {
                width: 70px;
            }
        }

        .information-detailBox {
            width: 65%;
            .mobile-box {
                font-size: 4.5vw;
                padding: 8px;
            }

            .address-box {
                margin-top: 3vw;
                align-items: center;
                img {
                    width: 12px;
                }
                font-size: 3vw;
            }

            .filings {
                font-size: 3vw !important;
                line-height: 5vw;
                margin-top: 1vw;
            }
        }
    }
}

@media (max-width: 375px) {
    .footBar {
        //padding-bottom: 2vh !important;
        .submit-allBox {
            padding: 2vw 0 0 0;
            .submit-leftBox {
                width: 95%;
                .contact-timeBox {
                    .time-numbers {
                        .time-number,
                        .bumbernone {
                            font-size: 3vw !important;
                        }
                    }
                    .time-txts {
                        display: flex;
                        margin-left: 3vw;
                    }
                }
                .contact-textBox {
                    .contact-title {
                        margin-bottom: 1vw !important;
                        font-size: 3.5vw !important;
                    }
                    .contact-details {
                        .contact-detail {
                            margin-bottom: 4px !important;
                            font-size: 3vw !important;
                        }
                    }
                    .contact-button {
                        font-size: 3vw !important;
                    }
                }
            }

            .submit-form {
                .form-titles {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    font-size: 3vw !important;
                    margin: 1vw 0;
                    .English-title {
                        margin-top: 3px !important;
                        font-size: 3.5vw !important;
                    }
                }
                .el-form-item {
                    margin-bottom: 20px;
                    .submit-button {
                        margin-top: 0px;
                    }
                }

                .sub-button {
                    margin-bottom: 1px !important;
                }
            }

            ::v-deep .el-form-item__error {
                font-size: 13px;
                padding-top: 4px;
            }
        }
    }
}

@media (max-width: 360px) {
    .information-allBox {
        .codeBox {
            span {
                font-size: 3vw !important;
            }
        }
        .information-detailBox {
            .mobile-box {
                font-size: 4.5vw !important;
                padding: 2vw !important;
            }
            .address-box {
                font-size: 3vw !important;
            }
        }
    }
}
</style>