// disableCtrlScroll.js
//禁止Ctrl+鼠标滚轮缩放页面大小
export default {
    bind(el) {
        el.addEventListener('wheel', (event) => {
            if (event.ctrlKey) {
                event.preventDefault();
            }
        });
    }
};
