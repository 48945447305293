import request from "@/utils/request";
//创作员- 书籍列表
export function upload(data) {
    return request({
        url: "/api/common/upload",
        method: "post",
        data
        // headers: {
        //   'token': JSON.parse(sessionStorage.getItem('userInfo')).token,
        // },
    });
}
//菜单
export function web_menu() {
    return request({
        url: "/api/home/web_menu",
        method: "post",
    });
}
//首页数据
export function home_info() {
    return request({
        url: "/api/home/home_info",
        method: "post",
    });
}
//提交留言
export function add_leave_word(data) {
    return request({
        url: "/api/home/add_leave_word",
        method: "post",
        data
    });
}
//底部数据
export function bottom_data() {
    return request({
        url: "/api/home/bottom_data",
        method: "post",
    });
}
//业务范畴-地震预警发布系统
export function earthquake_warning_system(data) {
    return request({
        url: "/api/home/earthquake_warning_system",
        method: "post",
        data
    });
}
//业务范畴-地震预警设备
export function earthquake_warning_device(data) {
    return request({
        url: "/api/home/earthquake_warning_device",
        method: "post",
        data
    });
}
//业务范畴-软硬件产品
export function software_hardware_products(data) {
    return request({
        url: "/api/home/software_hardware_products",
        method: "post",
        data
    });
}
//关于我们
export function about_us(data) {
    return request({
        url: "/api/home/about_us",
        method: "post",
        data
    });
}
//案例中心
export function case_center(data) {
    return request({
        url: "/api/home/case_center",
        method: "post",
        data
    });
}
//咨询管理
export function consult(data) {
    return request({
        url: "/api/home/consult",
        method: "post",
        data
    });
}
//联系我们
export function contact_us() {
    return request({
        url: "/api/home/contact_us",
        method: "post",
    });
}